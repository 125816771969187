import React from 'react'

import { useAuth } from '@azos/account'
import { NoSSR } from '@azos/shared'

import TemplateB2B from '../TemplateB2B'
import TemplateB2C from '../TemplateB2C'
import { TemplateDefaultProps } from '../TemplateDefault/TemplateDefault.props'

const TemplateMain: React.FCC<TemplateDefaultProps> = ({
  children,
  ...rest
}) => {
  const { user } = useAuth()

  const isB2B = user?.isBroker || user?.isInsuredByBroker

  const Template = isB2B ? TemplateB2B : TemplateB2C

  return (
    <NoSSR>
      <Template isLoading={!user} {...rest}>
        {children}
      </Template>
    </NoSSR>
  )
}

export default TemplateMain
