import React from 'react'

import { MenuContext, MenuContextData } from './MenuProvider'

const useMenu = (): MenuContextData => {
  const context = React.useContext(MenuContext)

  if (!context) {
    throw new Error('useAuth must be used within a MenuProvider')
  }

  return context
}

export { useMenu }
