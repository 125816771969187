import { css, Utils } from '@azos/shared'
import styled from '@emotion/styled'

interface StyleProps {
  $hasBottomNavigation: boolean
  $overflowMain: boolean
}

/* prettier-ignore */
export const Root = styled('div', Utils.transient.transientStyleProps)<StyleProps>(({ $hasBottomNavigation, $overflowMain, theme }) => css`
  padding-left: 0;

  @media (min-width: 600px) {
    padding-left: 104px;
  }

  @media (min-width: 1024px) {
    padding-left: 320px;
  }

  .container {
    display: flex;
    align-items: flex-start;
    gap: 28px;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    ${$hasBottomNavigation && css`
      padding-bottom: 100px;
    `}

    aside {
      width: 104px;
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      margin: 0;
      border-radius: 0;
      z-index: 1200;
      min-height: 100vh;
      transition: width .25s;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);

      &:hover {
        @media (pointer: fine) {
          width: 320px !important;
        }
      }

      @media (min-width: 600px) {
        display: block;
      }

      @media (min-width: 1024px) {
        width: 320px;
      }
    }


    &__mobile-header-content {
      background: ${theme.palette.neutral[400]};
      display: flex;
      flex-direction: column;
      padding: 12px 16px 12px;

      &--user {
        display: flex;
        align-items: center;
        position: relative;

        & > span {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 0;
          top: 0;
          width: 100%;
          height: 40px;
        }

      }

      &__text {
        margin-top: 24px;
        &--title {
          color: ${theme.palette.neutral[50]};


          span {
            color: ${theme.palette.primary.main};
            display: inline-block;
            margin-right: ${theme.spacing[1]}px;
          }
        }
      }
    }

    &__header-content {
      display: flex;
      align-items: center;

      button {
        margin-right: ${theme.spacing[3]}px;
      }

      &--title {
        color: ${theme.palette.neutral[50]};
        margin-right: ${theme.spacing[4]}px;

        span {
            color: ${theme.palette.primary.main};
            display: inline-block;
            margin-right: ${theme.spacing[1]}px;
          }
      }
    }

    main, > header {
      flex: 1;
      overflow: hidden;
      ${$overflowMain && css`
        overflow: visible;
      `}

      width: 100%;
    }
  }
`,
)

export default Root
