import React from 'react'

import { useAuth } from '@azos/account'
import { useRemoteConfig } from '@azos/core'
import { TextPolicyStatus } from '@domain/models'
import {
  DESKTOP_MENU_LABEL,
  lowerMenuB2C,
  MOBILE_MENU_LABEL,
  mobileBottomNavigation,
  upperMenuB2C,
  userAccountMenuItem,
  wizardMenuItem,
} from '@main/config/menu'
import {
  BottomNavigationItemsProps,
  SidebarDashboardMenuProps,
} from '@presentation/components/organisms'
import { useBanners } from '@presentation/providers/BannersProvider'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'

import TemplateDefault from '../TemplateDefault'
import { TemplateDefaultProps } from '../TemplateDefault/TemplateDefault.props'

const TemplateB2C: React.FCC<TemplateDefaultProps> = ({
  children,
  ...rest
}) => {
  const { user } = useAuth()
  const { policies } = useMyPolicies()
  const { onboardingBanner } = useBanners()
  const remoteConfig = useRemoteConfig()

  const hasActivePolicies = React.useMemo(() => {
    const inactivePoliciesStatus = [
      TextPolicyStatus.canceled,
      TextPolicyStatus.expired,
      TextPolicyStatus.pre_canceled,
    ]

    return (
      policies?.filter(
        policy =>
          !inactivePoliciesStatus.includes(
            policy.status[0].label as TextPolicyStatus,
          ),
      ).length > 0
    )
  }, [policies])

  const [bottomNavigation, setBottomNavigation] = React.useState<
    BottomNavigationItemsProps[]
  >(mobileBottomNavigation)

  const [menu, setMenu] = React.useState<SidebarDashboardMenuProps[]>([
    ...upperMenuB2C,
    ...lowerMenuB2C,
  ])

  React.useEffect(() => {
    const hasPolicies = !!(
      user && (user.insuredUser?.info.amountPolicies ?? 0) > 0
    )

    if (user && (!user?.isInsured || !hasPolicies)) {
      setBottomNavigation(bottomNavigationMenu =>
        bottomNavigationMenu.filter(
          menuItem => menuItem.label !== MOBILE_MENU_LABEL.MEUS_GUARDIOES,
        ),
      )

      let menuB2C: SidebarDashboardMenuProps[] = menu
      const menuItems: SidebarDashboardMenuProps[] = []

      if (onboardingBanner) {
        menuItems.push({
          ...wizardMenuItem,
          label: onboardingBanner.title,
          progress: onboardingBanner.icon.valueProgressIndicator as number,
          progressColor: onboardingBanner.icon.colorProgressIndicator as string,
          progressBackgroundColor: onboardingBanner.icon.iconBackgroundColor,
        })
      }

      menuB2C = [
        ...upperMenuB2C,
        userAccountMenuItem,
        ...menuItems,
        ...lowerMenuB2C,
      ]

      const myAccountItems = menuB2C.filter(
        item =>
          !(
            item.label === DESKTOP_MENU_LABEL.TUDO_SOBRE_SEGURO ||
            item.label === DESKTOP_MENU_LABEL.MINHAS_APOLICES
          ),
      )

      setMenu(myAccountItems)
    } else {
      let menuB2C: SidebarDashboardMenuProps[] = menu
      const menuItems: SidebarDashboardMenuProps[] = []

      if (onboardingBanner) {
        menuItems.push({
          ...wizardMenuItem,
          label: onboardingBanner.title,
          progress: onboardingBanner.icon.valueProgressIndicator as number,
          progressColor: onboardingBanner.icon.colorProgressIndicator as string,
          progressBackgroundColor: onboardingBanner.icon.iconBackgroundColor,
        })
      }

      menuB2C = [
        ...upperMenuB2C,
        userAccountMenuItem,
        ...menuItems,
        ...lowerMenuB2C,
      ]

      setMenu(menuB2C)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, onboardingBanner, hasActivePolicies])

  const finalMenu = React.useMemo((): SidebarDashboardMenuProps[] => {
    if (remoteConfig.get('B2C_MEMORE') && hasActivePolicies) {
      return menu
    }

    return menu.filter(item => item.label !== DESKTOP_MENU_LABEL.MEMORE)
  }, [hasActivePolicies, menu, remoteConfig])

  return (
    <TemplateDefault
      {...rest}
      sidebarMenu={finalMenu}
      bottomNavigationMenu={bottomNavigation}
    >
      {children}
    </TemplateDefault>
  )
}

export default TemplateB2C
