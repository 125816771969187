import React from 'react'

import { useAuth } from '@azos/account'
import { Utils as CoreUtils } from '@azos/core'
import { HeaderDashboard, Icon, useMediaQuery } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { MENU } from '@main/config/menu'
import { UserPhoto } from '@presentation/components/molecules/User'
import {
  BottomNavigation,
  SidebarDashboard,
} from '@presentation/components/organisms'
import { useMenu } from '@presentation/providers/MenuProvider/useMenu'
import { useRouter } from '@presentation/providers/RouterProvider'

import {
  TemplateChannelProps,
  TemplateDefaultProps,
} from './TemplateDefault.props'
import Root from './TemplateDefault.styles'

type Props = TemplateDefaultProps & TemplateChannelProps

const TemplateDefault: React.FCC<Props> = ({
  children,
  headerProps,
  navigationProps,
  isLoading,
  sidebarMenu,
  bottomNavigationMenu,
  isHomepage = false,
  overflowMain,
  headerContent,
  showBottomButton = true,
}) => {
  const router = useRouter()
  const [isUpdatePhotoOpen, setIsUpdatePhotoOpen] = React.useState(false)
  const { user } = useAuth()

  const { isMobile } = useMediaQuery()
  const { onChangeMenu, open } = useMenu()

  const bottomNavigationIsVisible = React.useMemo<boolean>(
    () => isMobile,
    [isMobile],
  )

  const [firstName] = CoreUtils.checkout.getNames(
    (user?.insuredUser?.socialName ||
      user?.insuredUser?.fullName ||
      user?.displayName) ??
      '',
  )

  const menuMobile: {
    [key: string]: string
  } = {
    [MENU.INICIO]: bottomNavigationMenu?.[0]?.value || '',
    [MENU.MINHAS_APOLICES]: bottomNavigationMenu?.[1]?.value || '',
    [MENU.BENEFICIOS]: bottomNavigationMenu?.[2]?.value || '',
    [MENU.MEUS_GUARDIOES]: bottomNavigationMenu?.[3]?.value || '',
    [MENU.MENU_GERAL]: MENU.MENU_GERAL,
  }

  const renderHeaderWithUser = () => (
    <div className="container__mobile-header-content">
      <div className="container__mobile-header-content--user">
        <UserPhoto
          isOpen={isUpdatePhotoOpen}
          setIsOpen={setIsUpdatePhotoOpen}
          user={user}
          {...headerProps}
        />
        <span>
          <Icon.AzosWhite size="small" />
        </span>
      </div>
      {isHomepage && (
        <div className="container__mobile-header-content__text">
          <Text
            className="container__mobile-header-content__text--title"
            variant="h4"
          >
            Olá{firstName ? ', ' : null}
            <span>{firstName}</span>! 👋
          </Text>
        </div>
      )}
      {headerContent && (
        <div className="container__header-custom-content">{headerContent}</div>
      )}
    </div>
  )

  const renderHeaderNoUser = () => (
    <HeaderDashboard>
      <div className="container__header-content">
        {isHomepage && (
          <>
            <UserPhoto
              isOpen={isUpdatePhotoOpen}
              setIsOpen={setIsUpdatePhotoOpen}
              user={user}
              {...headerProps}
            />
            <Text className="container__header-content--title" variant="h4">
              Olá{firstName ? ', ' : null}
              <span>{firstName}</span>! 👋
            </Text>
          </>
        )}
      </div>
      {headerContent && (
        <div className="container__header-custom-content">{headerContent}</div>
      )}
    </HeaderDashboard>
  )

  return (
    <>
      <Root
        $hasBottomNavigation={
          bottomNavigationIsVisible && !!bottomNavigationMenu
        }
        $overflowMain={!!overflowMain}
      >
        {isMobile
          ? user && renderHeaderWithUser()
          : (isHomepage || headerContent) && !isLoading && renderHeaderNoUser()}

        {!isLoading && !open && (
          <>
            <div className="container">
              {!isMobile && (
                <SidebarDashboard
                  items={sidebarMenu}
                  onChange={onChangeMenu((path: string) => router.push(path))}
                  isUserInsured={!!user?.isInsured}
                  {...navigationProps}
                />
              )}

              <main>{children}</main>
            </div>
          </>
        )}
      </Root>

      {bottomNavigationIsVisible && !!bottomNavigationMenu && (
        <BottomNavigation
          items={bottomNavigationMenu}
          value={
            menuMobile[open ? MENU.MENU_GERAL : String(navigationProps?.value)]
          }
          onChange={onChangeMenu((path: string) => router.push(path))}
          showBottomButton={showBottomButton}
        />
      )}
    </>
  )
}

export default TemplateDefault
