import React from 'react'

import { useAuth } from '@azos/account'
import { useRemoteConfig } from '@azos/core'
import { TextPolicyStatus } from '@domain/models'
import {
  DESKTOP_MENU_LABEL,
  lowerMenuB2B,
  mobileBottomNavigation,
  responsibleBrokerMenuItem,
  upperMenuB2B,
  userAccountMenuItem,
  wizardMenuItem,
} from '@main/config/menu'
import { SidebarDashboardMenuProps } from '@presentation/components/organisms'
import { useBanners } from '@presentation/providers/BannersProvider'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'

import TemplateDefault from '../TemplateDefault'
import { TemplateDefaultProps } from '../TemplateDefault/TemplateDefault.props'

const TemplateB2B: React.FCC<TemplateDefaultProps> = ({
  children,
  ...rest
}) => {
  const { user } = useAuth()
  const { onboardingBanner } = useBanners()
  const remoteConfig = useRemoteConfig()

  const [menu, setMenu] = React.useState<SidebarDashboardMenuProps[]>([
    ...upperMenuB2B,
    ...lowerMenuB2B,
  ])
  const { policies } = useMyPolicies()

  const hasActivePolicies = React.useMemo(() => {
    const inactivePoliciesStatus = [
      TextPolicyStatus.canceled,
      TextPolicyStatus.expired,
      TextPolicyStatus.pre_canceled,
    ]

    return (
      policies?.filter(
        policy =>
          !inactivePoliciesStatus.includes(
            policy.status[0].label as TextPolicyStatus,
          ),
      ).length > 0
    )
  }, [policies])

  const hasPolicies = React.useMemo(
    () =>
      policies.filter(
        policy => policy.status[0].label === TextPolicyStatus.in_effect,
      ).length > 0,
    [policies],
  )

  const handleNotInsuredUserMenu = React.useCallback(() => {
    let menuB2B: SidebarDashboardMenuProps[] = menu
    const menuItems: SidebarDashboardMenuProps[] = []

    if (onboardingBanner) {
      menuItems.push({
        ...wizardMenuItem,
        label: onboardingBanner.title,
        progress: onboardingBanner.icon.valueProgressIndicator as number,
        progressColor: onboardingBanner.icon.colorProgressIndicator as string,
        progressBackgroundColor: onboardingBanner.icon.iconBackgroundColor,
      })
    }

    if (user?.brokerUser) {
      menuItems.push(responsibleBrokerMenuItem)
    }

    menuB2B = [
      ...upperMenuB2B,
      userAccountMenuItem,
      ...menuItems,
      ...lowerMenuB2B,
    ]

    const myAccountItems = menuB2B.filter(
      item =>
        !(
          item.label === DESKTOP_MENU_LABEL.MEUS_GUARDIOES ||
          item.label === DESKTOP_MENU_LABEL.TUDO_SOBRE_SEGURO ||
          item.label === DESKTOP_MENU_LABEL.MINHAS_APOLICES
        ),
    )

    setMenu(myAccountItems)
  }, [menu, onboardingBanner, user])

  const handleInsuredUserMenu = React.useCallback(() => {
    let menuB2B: SidebarDashboardMenuProps[] = menu
    const menuItems: SidebarDashboardMenuProps[] = []

    if (onboardingBanner) {
      menuItems.push({
        ...wizardMenuItem,
        label: onboardingBanner.title,
        progress: onboardingBanner.icon.valueProgressIndicator as number,
        progressColor: onboardingBanner.icon.colorProgressIndicator as string,
        progressBackgroundColor: onboardingBanner.icon.iconBackgroundColor,
      })
    }

    if (user?.brokerUser) {
      menuItems.push(responsibleBrokerMenuItem)
    }

    menuB2B = [
      ...upperMenuB2B,
      userAccountMenuItem,
      ...menuItems,
      ...lowerMenuB2B,
    ]

    setMenu(menuB2B)
  }, [menu, onboardingBanner, user])

  React.useEffect(() => {
    const hasPolicies = !!(
      user && (user.insuredUser?.info.amountPolicies ?? 0) > 0
    )

    if (user && (!user?.isInsured || !hasPolicies)) {
      handleNotInsuredUserMenu()
    } else {
      handleInsuredUserMenu()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, onboardingBanner, hasPolicies])

  const finalMenu = React.useMemo((): SidebarDashboardMenuProps[] => {
    if (remoteConfig.get('B2C_MEMORE') && hasActivePolicies) {
      return menu
    }

    return menu.filter(item => item.label !== DESKTOP_MENU_LABEL.MEMORE)
  }, [menu, remoteConfig, hasActivePolicies])

  return (
    <TemplateDefault
      {...rest}
      sidebarMenu={finalMenu}
      bottomNavigationMenu={mobileBottomNavigation}
    >
      {children}
    </TemplateDefault>
  )
}

export default TemplateB2B
